import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"
import { getTableFilter } from "./RateGroupsCollection.filter"
import { getTableActions } from "./RateGroupsCollection.actions"
import { getTableColumns } from "./RateGroupsCollection.columns"

const RateGroupsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()

    const filterConfig = getTableFilter(t)

    const actions = getTableActions(t, apiClient, volcanoAuth.user)

    const table = {
        actions: actions,
        columns: getTableColumns(t),
        fetcher: (params) => {
            // check config parameters
            if (params.config) {
                params = {
                    ...params,
                    ...params.config,
                }

                delete params.config                    
            }

            return apiClient.catalog.rateGroup.getRateGroups(params)
        },
    }

    return (
        <CollectionPage
            title={t("catalog.rate_groups.title")}
            subtitle={t("catalog.rate_groups.subtitle")}
            actions={actions.filter((action) => action.scope === "page")}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default RateGroupsCollectionPage