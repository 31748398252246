import { downloadFile, getSuggestedFilename, openInNewTab } from "../../../../lib/utils"

export const getShareActions = (user, apiClient, entityId, t) => [
    {
        id: "link",
        title: t("common.actions.share.options.link"),
        onExecute: (rateGroup, progressHandler, onSuccess, onError) => {
            navigator.clipboard.writeText(window.location.href)
            onSuccess(t("common.actions.share.confirm"))
        },
    },
    /*{
        id: "link_pdf",
        title: t("common.actions.share.options.open_pdf"),
        onExecute: (rateGroup, progressHandler, onSuccess, onError) => {
            const data = {
                resource: "rate_groups",
                operation: "rategroup_pdf",
                id: rateGroup.id,
                params: {
                    title: getSuggestedFilename(rateGroup.name)
                }
            }
            return apiClient.download.createLink(data)
                .then((link) => {
                    openInNewTab(link.url);
                })
                .catch((error) => {
                    onError(t("common.actions.share.error"))
                })
        },
    },*/
    {   
        id: "download_pdf",
        title: t("common.download"),
        onExecute: (rateGroup, progressHandler, onSuccess, onError) => {
            apiClient.catalog.rateGroup.getRateGroupPdf(rateGroup.id).then(pdf => {
                downloadFile(pdf, getSuggestedFilename(rateGroup.name) + ".pdf")
            })
        },
    }
]