import constants from "../../../components/entity/constants";
import {createAsyncSelectFormField, createField} from '../../../components/helpers/fieldHelper';
import {areasFetcher} from "../../../lib/form-options-fetchers";

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("zones.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "area",
        t("zones.fields.area"),
        (params) => areasFetcher(cache, apiClient, t, params,true),
        false
    ),
])