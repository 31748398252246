import { createTabBarItem } from "../../../../components/helpers/fieldHelper";
import userPermissions from "../../../../lib/user-permissions";
import RateGroupProducts from "./components/tabs/RateGroupProducts";

export const getTabNavigationItems = (user, t) => [
    createTabBarItem(
        t("catalog.rate_groups.view.navbar.products"),
        RateGroupProducts,
    ),
    /*createTabBarItem(
        t("catalog.rate_groups.view.navbar.intermediaries"),
        RateGroupIntermediaries,
        null,
        () => user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW)
    ),*/
]