import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { createBooleanFormField, createField, createFormField, createFormFieldset, createPercentageField } from "../../../../components/helpers/fieldHelper"
import EntityEditForm from "../../../actions/EntityEditForm"


const RateGroupForm = ({ selection, onAction }) => {
    const { t } = useTranslation("vbms")

    const entity = selection || {}

    const formContent = [
        createFormFieldset(null, [
            createFormField(
                createField(
                    "name",
                    t("catalog.rate_groups.fields.name"),
                    constants.STRING_TYPE
                ),
                true
            ),
            createBooleanFormField(
                t,
                "config.protected",
                t("catalog.rate_groups.fields.config_protected"),
                null,
                false
            ),
            {
                ...createFormField(
                    createPercentageField(
                        createField(
                            "config.default_commission",
                            t("catalog.rate_groups.fields.config_default_commission"),
                            constants.NUMERIC_TYPE
                        )
                    ),
                    true,
                    0.0
                ),
                max_width: "100%"
            }
        ])
    ]

    return (
        <EntityEditForm
            selection={entity}
            formContent={formContent}
            onAction={onAction}
        />
    )
}

export default RateGroupForm