import { MoreHoriz } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Stack, useMediaQuery, useTheme } from "@mui/material"
import _get from "lodash/get"
import _isFunction from "lodash/isFunction"
import { useState } from "react"
import { useDialog } from "../Dialog/dialog.context"
import useSnackBars from "../Snackbar/snack-bar.context"
import Action from "./Action"
import Loading from "../Display/Loading";
import availableRoutes from '../../routes/availableRoutes';

const ActionsMenu = ({ actions, selection, onAction }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAction = (action, selection, progressHandler, onSuccess, onError, state) => {
        handleClose()
        onAction(action, selection, progressHandler, onSuccess, onError, state)
    }

    return (
        <Box>
            <IconButton onClick={handleOpen}><MoreHoriz /></IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                sx={{ width: "100%" }}
            >
                {actions.map((action, index) => (
                    <MenuItem sx={{ fontSize: "body1.fontSize", fontWeight: 500, minHeight: 0, color: "primary.main" }}
                        key={"actionMenuItem" + index}
                        onClick={() => handleAction(action, selection)}
                        disabled={action.disabled ?? false}
                    >
                        {action.title}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )

}

const BaseActionsGroup = ({ selection, actions, variant, actionsNumberMobile, actionsNumberDesktop, navigate }) => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const { showDialog, showConfirmDialog, handleClose } = useDialog()
    const { addAlert } = useSnackBars()
    const [progress, setProgress] = useState({ open: false, value: -1, message: null })

    if (!actions || actions.length === 0) {
        return null
    }

    actionsNumberMobile = actionsNumberMobile ? actionsNumberMobile : 1
    actionsNumberDesktop = actionsNumberDesktop ? actionsNumberDesktop : 3

    const buttonActions = (!matchDownMd && actions.slice(0, actionsNumberDesktop)) || actions.slice(0, actionsNumberMobile)
    const menuActions = (matchDownMd && actions.slice(actionsNumberMobile, actions.length)) || (actions.length > actionsNumberDesktop && actions.slice(actionsNumberDesktop, actions.length))

    const onSuccessHandler = (message) => addAlert(message, "success")

    const onErrorHandler = (message) => addAlert(message, "error")

    const progressHandler = (progress, message) => {
        setProgress((prev) => {
            return {
                open: prev.open && progress < 100,
                value: progress === 100 ? 0 : progress,
                message: message
            }
        })
    }

    const handleAction = (action) => {
        const confirmDialog = _get(action, "confirmDialog", false)
        const dialog = !confirmDialog && _get(action, "withDialog", false)
        const withProgress = _get(action, "withProgress", false)

        if (action.onExecute) {
            if (withProgress) {
                setProgress({
                    open: true,
                    value: 0,
                })
                action.onExecute(selection, progressHandler)
            } else if (confirmDialog) {
                showConfirmDialog(
                    confirmDialog.title,
                    (setState) => confirmDialog.content(selection, setState),
                    (state) => {
                        if (action.showLoading) {
                            setProgress({
                                open: true,
                                value: -1,
                                message: null
                            })
                            return action
                                .onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)
                                .finally(() => action.showLoading ? progressHandler(100) : null)
                        } else {
                            return action
                                .onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)

                        }
                    },
                )
            } else if (dialog) {
                if (_isFunction(dialog.content)) {
                    const condition = _isFunction(dialog.condition) ? dialog.condition(selection) : true

                    if (!condition) {
                        if (action.showLoading) {
                            setProgress({
                                open: true,
                                value: -1
                            })
                            return action
                                .onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)
                                .finally(() => action.showLoading ? progressHandler(100) : null)

                        } else {
                            return action
                                .onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)
                        }
                    }

                    const Content = dialog.content

                    showDialog(
                        dialog.title,
                        <Content
                            selection={selection}
                            onClose={handleClose}
                            onAction={(data) => {
                                if (action.showLoading) {
                                    setProgress({
                                        open: true,
                                        value: -1
                                    })
                                    return action
                                        .onExecute(data, progressHandler, onSuccessHandler, onErrorHandler)
                                        .finally((data) => {
                                            if (action.showLoading) {
                                                progressHandler(100)
                                            }
                                            return data
                                        })
                                } else {
                                    return action
                                        .onExecute(data, progressHandler, onSuccessHandler, onErrorHandler)
                                }
                            }}
                        />,
                        null,
                        dialog.maxWidth || "sm"
                    )
                }
            } else {
                if (action.showLoading) {
                    setProgress({
                        open: true,
                        value: -1
                    })
                    return action
                        .onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)
                        .finally(() => action.showLoading ? progressHandler(100) : null)

                } else {
                    return action
                        .onExecute(selection, progressHandler, onSuccessHandler, onErrorHandler)
                }
            }
        } else if (action.link) {
            let url = availableRoutes[action.link.route].path

            if (!_get(action.link, "field", null) != null) {
                url += "/" + _get(selection, action.link.field)
            }

            navigate(url)
        }
    }

    return (
        <Box className="base-actions-group" display="flex" alignItems="center">
            <Stack direction="row" spacing={1}>
                {buttonActions.map((action, index) => (
                    <Action
                        key={"actionButton" + index}
                        variant={action.variant || variant || (index > 0 ? "outlined" : "contained")}
                        action={action}
                        onAction={handleAction}
                        disabled={action.disabled ?? false}
                    />
                ))}
            </Stack>
            {menuActions && menuActions.length > 0 && <ActionsMenu actions={menuActions} selection={selection} onAction={handleAction} />}
            {progress.open && <Loading open={progress.open} progress={progress.value} title={progress.message} />}
        </Box>
    )
}

export default BaseActionsGroup