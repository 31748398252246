import { Box } from "@mui/material"
import AsyncEntityForm from "../../components/form/AsyncEntityForm"

const EntityEditForm = ({ selection, formContent, onAction }) => {

    const fetcher = () => Promise.resolve(selection)

    const onConfirm = (result) => {
        onAction({
            id: selection?.id || null,
            ...result
        })
    }

    return (
        <Box
            sx={{
                '& .vte-form-field': {
                    maxWidth: "100% !important",
                },
            }}
        >
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
        </Box>
    )
}

export default EntityEditForm