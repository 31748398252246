import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { formatDecimal } from "../../../lib/utils"

const BACKGROUND_COLORS = ["#f5f5f5", "#ffffff"]

const UNK_VALUE_CHAR = "-"

const processPaxRestrictionValue = (value) => {
    if (value === null || value === undefined || value === -1) {
        return UNK_VALUE_CHAR
    }

    return value
}

const ContentTableCell = ({ align, backgroundColor, colSpan, width, whiteSpace, children }) => (
    <TableCell
        size="small"
        align={align || "left"}
        colSpan={colSpan || 1}
        width={width || "auto"}
        whiteSpace={whiteSpace || "nowrap"}
        sx={{
            fontSize: "0.775rem",
            backgroundColor: backgroundColor || BACKGROUND_COLORS[1]
        }}
    >
        {children}
    </TableCell>
)

const EmptyTableCell = (props) => <ContentTableCell {...props} />

const ProductRatesTableHead = ({ product }) => {
    const { t } = useTranslation("vbms")

    const backgroundColor = BACKGROUND_COLORS[0]

    const qtyRestrictions = product.getQtyRestrictions()

    return (
        <TableHead>
            <TableRow>
                <EmptyTableCell colSpan={2} width={"25%"}/>
                <Fragment key={"rate-group.id"}>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.config.min_pax")}</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.config.max_pax")}</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.net_price")}</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>%</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.active")}</ContentTableCell>
                </Fragment>
            </TableRow>
            <TableRow>
                <ContentTableCell>{t("catalog.rates_table.rates.customer_type")}</ContentTableCell>
                <ContentTableCell align="right">{t("catalog.rates_table.rates.retail_price")}</ContentTableCell>
                <Fragment key={"rate.group.id"}>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(qtyRestrictions.min)}</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(qtyRestrictions.max)}</ContentTableCell>
                    <EmptyTableCell backgroundColor={backgroundColor} colSpan={2} />
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{product.active ? t("common.yes") : t("common.no")}</ContentTableCell>
                </Fragment>
            </TableRow>
        </TableHead>
    )
}

const ProductRatesTableBodyRow = ({ rate, rateGroups }) => {
    const { t, i18n } = useTranslation("vbms")

    return (
        <TableRow
            key={rate.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">{rate.customer_types.name}</TableCell>
            <TableCell align="right">{rate.retail_price.formatted_currency}</TableCell>
            {rateGroups.map((rateGroup, index) => {
                const backgroundColor = index % 2 === 0 ? "#f5f5f5" : "#ffffff"
                const rateGroupRate = rateGroup.rates.find((rateGroupRate) => rateGroupRate.customer_type_ids === rate.customer_type_ids)

                const active = rateGroupRate.active

                const data = {
                    price: rateGroupRate.net_price.formatted_currency,
                    percentage: formatDecimal(i18n, parseFloat(rateGroupRate.commission_percentage) * 100),
                    active: active,
                    qty_restrictions: {
                        min: rateGroupRate.qty_restrictions?.min || 0,
                        max: rateGroupRate.qty_restrictions?.max || 0,
                    },
                }

                return (
                    <Fragment key={rateGroup.id}>
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(data.qty_restrictions.min)}</ContentTableCell>
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(data.qty_restrictions.max)}</ContentTableCell>
                        <TableCell
                            align="right"
                            sx={{ backgroundColor: backgroundColor }}
                        >
                            {data.price}
                        </TableCell>
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{data.percentage}</ContentTableCell>
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{data.active ? t("common.yes") : t("common.no")}</ContentTableCell>
                    </Fragment>
                )
            })}
        </TableRow>
    )
}

const ProductRatesTableBody = ({ productRates, rateGroups }) => {
    const { i18n } = useTranslation()

    rateGroups = rateGroups.map((rateGroup) => ({...rateGroup, rates: productRates}))

    return (
        <TableBody>
            {productRates.map((rate) => <ProductRatesTableBodyRow key={rate.id} rate={rate} rateGroups={rateGroups} />)}
        </TableBody>
    )
}

const ProductRatesTable = ({ product }) => {
    if (!product.rates) {
        return null
    }

    const rateGroups = [{id: 'test'}]
    
    return (
        <Box>
            <Paper elevation={0}>
                <TableContainer sx={{ maxWidth: "100%" }}>
                    <Table size="small">
                        <ProductRatesTableHead product={product} />
                        <ProductRatesTableBody productRates={product.rates} rateGroups={rateGroups} />
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default ProductRatesTable