import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material"
import RateGroupForm from "../actions/RateGroupForm"

export const getTableActions = (t, apiClient, user) => [
    {
        title: t("catalog.rate_groups.add"),
        scope: "page",
        withDialog: {
            title: t("catalog.rate_groups.actions.add.modal_title"),
            content: RateGroupForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.catalog.rateGroup.addRateGroup(result)
                .then((result) => {
                    onSuccess(
                        t("catalog.rate_groups.actions.add.confirm", {
                            name: result.name,
                        })
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("catalog.rate_groups.actions.add.error", {
                            name: result.name,
                        })
                    )
                })
        },
        condition: () => true // user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
    },
    {
        id: "edit",
        title: t("common.edit"),
        scope: "row",
        icon: EditOutlined,
        withDialog: {
            title: t("catalog.rate_groups.actions.edit.modal_title"),
            content: RateGroupForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.catalog.rateGroup.editRateGroup(result.id, result)
                .then((result) => {
                    onSuccess(
                        t("catalog.rate_groups.actions.edit.confirm", {
                            name: result.name,
                        })
                    )

                    return result
                })
                .catch((error) => {
                    onError(
                        t("catalog.rate_groups.actions.edit.error", {
                            name: result.name,
                        })
                    )
                })
        },
        condition: (entity) => entity.hasAction("edit")
    },
    {
        id: "delete",
        title: t("common.delete"),
        scope: "row",
        icon: DeleteOutlineOutlined,
        confirmDialog: {
            title: t("catalog.rate_groups.actions.delete.modal_title"),
            content: (selection) =>
                t("catalog.rate_groups.actions.delete.modal_content", {
                    name: selection.name,
                }),
        },
        reloadOnSuccess: true,
        onExecute: (entity, progressHandler, onSuccess, onError) => {
            return apiClient.catalog.rateGroup.deleteRateGroup(entity.id)
                .then(() => {
                    onSuccess(
                        t("catalog.rate_groups.actions.delete.confirm", {
                            name: entity.name,
                        })
                    )

                    return entity
                })
                .catch((error) => {
                    onError(
                        t("catalog.rate_groups.actions.delete.error", {
                            name: entity.name,
                        })
                    )

                    throw error
                })
        },
        condition: (entity) => entity.hasAction("delete")
    },
]