import { downloadFile, getSuggestedFilename, openInNewTab } from "../../../lib/utils"

export const getShareActions = (user, apiClient, entityId, t) => [
    {
        id: "link",
        title: t("common.actions.share.options.link"),
        onExecute: (rateGroup, progressHandler, onSuccess, onError) => {
            navigator.clipboard.writeText(window.location.href)
            onSuccess(t("common.actions.share.confirm"))
        },
    },
    {   
        id: "download_pdf",
        title: t("common.download"),
        onExecute: (intermediary, progressHandler, onSuccess, onError) => {
            apiClient.intermediary.getIntermediaryPdf(intermediary.id).then(pdf => {
                downloadFile(pdf, getSuggestedFilename(intermediary.name) + ".pdf")
            })
        },
    }
]