import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import RatesContainerEditor from "./RatesContainerEditor"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DataWrapper from "../../../../components/Display/DataWrapper"
import BaseActionsGroup from "../../../../components/action/BaseActionsGroup"
import _isFunction from "lodash/isFunction"

const initResult = (selection) => ({
    experience_id: selection.experience.id,
    experience_name: selection.experience.name,
    active: selection.active,
    config: {
        qty_restrictions: {
            min: selection?.container_configuration?.qty_restrictions?.min || null,
            max: selection?.container_configuration?.qty_restrictions?.max || null,    
        }
    },
    rates: selection.rates.map(rate => ({
        id: rate.id,
        name: rate.customer_types.name,
        customer_type_ids: rate.customer_type_ids,
        retail_price: parseFloat(rate.retail_price.value),
        net_price: parseFloat(rate.net_price.value),
        commission_rate: parseFloat(rate.commission_percentage),
        active: rate.active,
    }))
})

const initProductContainerResult = (value) => ({
    experience_id: value.experience_id,
    experience_name: value.experience_name,
    active: value.active,
    config: value.config,
})

const getQtyRestrictionText = (value) => {
    if (value === null || value === undefined || value === "" || value <= 0) {
        return ["catalog.rates_table.rates.config.default_pax_unlimited"]
    }

    return ["catalog.rates_table.rates.config.default_pax_value", { value: value }]
}

const ProductContainerForm = ({ product, defaultConfig, onChange }) => {
    const { t } = useTranslation("vbms")

    const onExperienceChangeHandler = (e) => {
        onChange({
            ...initProductContainerResult(product),
            experience_id: e.target.value,
        })
    }

    const onActiveChangeHandler = (e) => {
        onChange({
            ...initProductContainerResult(product),
            active: e.target.checked,
        })
    }

    const onQtyRestrictionChangeHandler = (field, value) => {
        let restriction = parseInt(value) || null
        if (restriction < 0) {
            restriction = null
        }

        onChange({
            ...initProductContainerResult(product),
            config: {
                ...product.config,
                qty_restrictions: {
                    ...product.config.qty_restrictions,
                    [field]: restriction,
                }
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value="active"
                        control={
                            <Checkbox
                                checked={product.active}
                                onChange={onActiveChangeHandler}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t("catalog.rates_table.rates.active")}
                        labelPlacement="start"
                        sx={{ ml: 0 }}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={6}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="experience-label">{t("products.fields.experience")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product.experience_id}
                        label={t("products.fields.experience")}
                        onChange={onExperienceChangeHandler}
                        variant="outlined"
                    >
                        <MenuItem value={product.experience_id}>{product.experience_name}</MenuItem>
                        <MenuItem value={2}>Experiencia 2</MenuItem>
                        <MenuItem value={3}>Experiencia 3</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <Stack spacing={1} direction="row">
                    <TextField
                        label={t("catalog.rates_table.rates.config.min_pax")}
                        type="number"
                        value={product?.config?.qty_restrictions?.min}
                        onChange={(e) => onQtyRestrictionChangeHandler("min", e.target.value)}
                        variant="outlined"
                        size="small"
                        helperText={t(...getQtyRestrictionText(defaultConfig?.qty_restrictions?.min))}
                    />
                    <TextField
                        label={t("catalog.rates_table.rates.config.max_pax")}
                        type="number"
                        value={product?.config?.qty_restrictions?.max}
                        onChange={(e) => onQtyRestrictionChangeHandler("max", e.target.value)}
                        variant="outlined"
                        size="small"
                        helperText={t(...getQtyRestrictionText(defaultConfig?.qty_restrictions?.max))}

                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

const ProductContainerEditor = ({ selection, onAction, onClose }) => {
    const { t } = useTranslation("vbms")
    const [result, setResult] = useState(initResult(selection))

    useEffect(() => {
        console.log(result)
    }, [result])

    const onProductChangeHandler = (product) => {
        setResult((prevResult) => ({
            ...prevResult,
            active: product.active,
            config: product.config,
        }))
    }

    const onRatesChangeHandler = (rates) => {
        console.log("Rates:", rates)
        setResult((prevResult) => ({
            ...prevResult,
            rates: rates
        }))
    }

    const actions = [
        {
            id: "save",
            title: t("common.save"),
            onExecute: () => {
                if (_isFunction(onAction)) {
                    return onAction({
                        id: selection.id,
                        name: selection.name,
                        data: {
                            id: selection.id,
                            ...result
                        }
                    })
                    .then((product) => {
                        onClose()
                        return product
                    })
                }
            },
        },
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: () => {
                if (_isFunction(onClose)) {
                    return onClose()
                }
            },
        }
    ]

    return (
        <Stack spacing={2}>
            <DataWrapper
                title={selection.name}
                subtitle={t("catalog.rates_table.form.container_description", { container_name: selection.container.name })}
                subtitleVariant="text"
            >
                <Stack spacing={2}>
                    <ProductContainerForm
                        product={result}
                        defaultConfig={{
                            qty_restrictions: selection?.qty_restrictions || {}
                        }}
                        onChange={onProductChangeHandler}
                    />
                    <RatesContainerEditor rates={result.rates} onChange={onRatesChangeHandler} />
                </Stack>
            </DataWrapper>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <BaseActionsGroup
                    selection={result}
                    actions={actions}
                    actionsNumberDesktop={2}
                />
            </Box>
        </Stack>
    )
}

export default ProductContainerEditor