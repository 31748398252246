import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurrencyField from "../../../../components/form/CurrencyField";
import { formatCurrencyCode, formatPrice } from "../../../../lib/utils";

const parseRateAmount = (amount) => {
    let nAmount = amount.endsWith(",") ? amount.replace(",", ".") : parseFloat(amount.replace(",", ".")) // parse to float

    nAmount = isNaN(nAmount) && amount.length > 0 && !amount.endsWith(".") ? "" : nAmount // remove other chars than decimal separator

    return isNaN(nAmount) || amount.endsWith(".") ? amount : nAmount
}

const AmountTextField = ({ id, value, onChange, i18n, currency, mainCurrency }) => {
    return (
        <Box display="inline-flex">
            <CurrencyField
                id={id.toString()}
                name={id.toString()}
                value={value}
                InputProps={{ size: "small", sx: { marginTop: "-2px", paddingY: "0px" } }}
                onChange={(e) => onChange(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ paddingTop: 0 }}
            />
            <Box sx={{ marginLeft: 1, marginY: "6px" }}>{formatCurrencyCode(i18n, currency, mainCurrency)}</Box>
        </Box>
    )
}

const RateRowComponent = ({ rate, onChange }) => {
    const { i18n } = useTranslation("vbms")

    const onChangeHandler = (field, value) => {
        if (rate[field] !== value) {
            if (field === 'active') {
                onChange({ ...rate, [field]: value })
            } else {
                const newRate = { ...rate, [field]: parseRateAmount(value) }
                if (field === 'net_price') {
                    newRate.commission_rate = (rate.retail_price - value) / rate.retail_price
                } else if (field === 'commission_rate') {
                    newRate.net_price = rate.retail_price * (1 - value)
                }
                onChange(newRate)
            }
        }
    }

    return (
        <TableRow key={rate.id}>
            <TableCell>{rate.name}</TableCell>
            <TableCell align="right">{formatPrice(i18n, "EUR", rate.retail_price, "EUR", 1)}</TableCell>
            <TableCell align="right">
                <AmountTextField
                    id="net_price"
                    value={rate.net_price}
                    i18n={i18n}
                    currency={"EUR"}
                    mainCurrency={"EUR"}
                    onChange={(value) => onChangeHandler("net_price", value)}
                />
            </TableCell>
            <TableCell align="right">
                <TextField
                    type="number"
                    value={rate.commission_rate}
                    onChange={(e) => onChangeHandler("commission_rate", e.target.value)}
                    variant="outlined"
                    size="small"
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={rate.active}
                    onChange={(e) => onChangeHandler("active", e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </TableCell>
        </TableRow>
    )
}

const RatesContainerEditor = ({ rates, onChange }) => {
    const { t } = useTranslation("vbms")

    const onChangeHandler = (newRate) => {
        const updatedRates = rates.map(rate => (rate.id === newRate.id) ? newRate : rate)
        onChange(updatedRates)
    }

    return (
        <TableContainer component={Box}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("catalog.rates_table.rates.name")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.retail_price")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.net_price")}</TableCell>
                        <TableCell align="right">{t("catalog.rates_table.rates.commission_rate")}</TableCell>
                        <TableCell>{t("catalog.rates_table.rates.active")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rates.map(rate => (<RateRowComponent rate={rate} onChange={onChangeHandler} />))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RatesContainerEditor