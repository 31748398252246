import constants from "../../../../components/entity/constants";
import { createDataSetFacet, createField } from "../../../../components/helpers/fieldHelper";

export const getHeaderContent = (t) => [
    createDataSetFacet(t("catalog.rate_groups.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "modified",
            t("common.fields.modified"),
            constants.DATETIME_TYPE
        )
    ]),
    createDataSetFacet(t("catalog.rate_groups.view.facet_configuration"), [
        createField(
            "config.protected",
            t("catalog.rate_groups.fields.config_protected"),
            constants.BOOLEAN_TYPE
        ),
        createField(
            "config.default_commission",
            t("catalog.rate_groups.fields.config_default_commission"),
            constants.PERCENTAGE_TYPE
        ),
    ]),    
]