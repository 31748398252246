import { useTranslation } from "react-i18next"
import constants from "../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createCallbackField } from "../../../components/helpers/fieldHelper"
import AsyncEntityPage from "../../../layout/templates/AsyncContentPage/AsyncEntityPage"
import { getActions } from './IntermediaryPage.actions';
import { getHeaderContent } from './IntermediaryPage.header'
import { getTabNavigationItems } from "./IntermediaryPage.tabs"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { getShareActions } from "./IntermediaryPage.shareActions"


const IntermediaryPage = (props) => {
    const { t } = useTranslation();
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const volcanoAuth = useVolcanoAuth()


    const fetcher = () => apiClient.intermediary.getIntermediary(entityId)

    return (
        <AsyncEntityPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("intermediaries.view.title"),
                constants.STRING_TYPE,
                (value) => {
                    return t("intermediaries.view.title", { name: value })
                }
            )}
            headerContent={getHeaderContent(t, volcanoAuth.user)}
            actions={getActions(apiClient, entityId, t, navigate, volcanoAuth.user)}
            shareActions={getShareActions(volcanoAuth.user, apiClient, entityId, t)}
            tabNavigationItems={getTabNavigationItems(fetcher, volcanoAuth.user, apiClient, t)}
        />
    )
}

export default IntermediaryPage