import { createExportField } from "../../../components/helpers/fieldHelper"
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import constants from "../../../components/entity/constants"
import { pushFields, dateObject, extractArrayField } from "../../../lib/utils"
import userPermissions from "../../../lib/user-permissions";

export const getTableExport = (apiClient, t, user, filterConfig) => {

    const columns = [
        createExportField("id", "Id"),
        createExportField(
            "name", 
            t("intermediaries.fields.name")
        ),
        createExportField(
            "rate_group.name",
            t("intermediaries.fields.parent_rates_collaborator")
        ),
        createExportField(
            "state.name",
            t("intermediaries.fields.state")
        ),
        createExportField(
            "intermediary_type.name",
            t("intermediaries.fields.intermediary_type")
        ),
        createExportField(
            "seller_type.name",
            t("intermediaries.fields.seller_type")
        ),
        createExportField(
            "location_type.name",
            t("intermediaries.fields.location_type")
        ),
        createExportField(
            "billing_type.name",
            t("intermediaries.fields.billing_type")
        ),
        createExportField(
            "created",
            t("common.fields.created"),
            null,
            (intermediary) => dateObject(intermediary.created)
        ),
        createExportField(
            "payment_methods.name",
            t("intermediaries.fields.payment_methods"),
            null,
            (intermediary) => extractArrayField(intermediary.payment_methods, "name")
        ),
        createExportField(
            "booking_contact.contact_details.email",
            t("common.fields.contact_details.email")
        ),
    ]

    const conditionalColumns = [
        {
            field: createExportField(
                "salesman.name",
                t("intermediaries.fields.crm_salesman")
            ),
            condition: user.hasPermission(userPermissions.PERM_CRM_SALESMAN_VIEW)
        }
    ]
    
    pushFields(columns, conditionalColumns)

    const columnToDelete = [
        {
            field: createExportField(
                    "collaborator_id",
                    t("intermediaries.fields.collaborator_id")
                ),
            condition: user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW)
        }
    ]

    pushFields(columns, columnToDelete)

    return (filter, progressHandler) => {
        const params = {
            ...filter,
        }

        filterConfig
            .filter(field => field.type === constants.DATE_TYPE)
            .forEach(field => {
                const date = _get(params, field.id)
                if (_isDate(date)) {
                    _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
                }
            })

        apiClient.intermediary.exportIntermediaries(
            columns,
            params,
            progressHandler
        )
    }
}
