import constants from "../../../../components/entity/constants"
import { createField, createLinkField } from "../../../../components/helpers/fieldHelper"


export const getTableColumns = (t) => ([
    createLinkField(
        createField(
            "name",
            t("catalog.rate_groups.fields.name"),
            constants.STRING_TYPE
        ),
        {
            route: "rate_groups",
            field: "id",
        }
    ),
    createField(
        "config.protected",
        t("catalog.rate_groups.fields.config_protected"),
        constants.BOOLEAN_TYPE
    ),
    createField(
        "config.default_commission",
        t("catalog.rate_groups.fields.config_default_commission"),
        constants.PERCENTAGE_TYPE
    ),
    createField(
        "created",
        t("common.fields.created"),
        constants.DATETIME_TYPE
    ),
    createField(
        "modified",
        t("common.fields.modified"),
        constants.DATETIME_TYPE
    ),    
])