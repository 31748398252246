import { useEffect, useState } from "react";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { Box, CircularProgress } from "@mui/material";
import ProductsTable from "../../../../components/ProductsTable";
import { useTranslation } from "react-i18next";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import ProductContainerEditor from "../../../../components/form/ProductContainerEditor";

const RateGroupProducts = ({ entity }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const [products, setProducts] = useState(null)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (!entity) {
            return
        }

        apiClient.catalog.rateGroup.getRateGroupProducts(entity.id, {limit: 1000}).then((products) => {
            setProducts(products.getItems().map((product) => {
                product.container = {
                    id: entity.id,
                    name: entity.name,
                }

                return product
            }))
        })        
    }, [entity, apiClient])

    useEffect(() => {
        if (products) {
            setReady(true)
        }
    }, [products, setReady])

    if (!entity) {
        return null;
    }

    const actions = [
        {
            id: "edit",
            title: t("common.edit"),
            scope: "row",
            icon: EditOutlined,
            withDialog: {
                title: t("catalog.product_containers.actions.product_edit.modal_title"),
                content: ProductContainerEditor,
                maxWidth: "md",
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (result, progressHandler, onSuccess, onError) => {
                return apiClient.catalog.rateGroup.editRateGroupProduct(entity.id, result.id, result.data)
                    .then((product) => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_edit.confirm", {
                                name: result.name,
                            })
                        )

                        return product
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_edit.error", {
                                name: result.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (entity) => entity.hasAction("edit"),
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: "row",
            icon: DeleteOutline,
            confirmDialog: {
                title: t("catalog.product_containers.actions.product_delete.modal_title"),
                content: (selection) =>
                    t("catalog.product_containers.actions.product_delete.modal_content", {
                        name: selection.name,
                    }),
            },
            reloadOnSuccess: true,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                return apiClient.catalog.rateGroup.deleteRateGroupProduct(entity.id, product.id)
                    .then(() => {
                        onSuccess(
                            t("catalog.product_containers.actions.product_delete.confirm", {
                                name: product.name,
                                container_name: entity.name,
                            })
                        )

                        return entity
                    })
                    .catch((error) => {
                        onError(
                            t("catalog.product_containers.actions.product_delete.error", {
                                name: entity.name,
                                container_name: entity.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (entity) => entity.hasAction("delete"),
        }
    ]

    return (
        <Box>
            {!ready && <Box display="inline-flex" alignItems="center"><CircularProgress size={20} /></Box>}
            {ready && <ProductsTable products={products} actions={actions} hideCustomConditions={true} />}
        </Box>        
    )
}

export default RateGroupProducts