import { Box, Checkbox, FormControlLabel, Grid, Stack, TextField } from "@mui/material"
import DataContainer from "../../../components/Display/DataContainer"
import ProductRatesTable from "./ProductRatesTable"
import { useEffect, useState } from "react"
import BaseActionsGroup from "../../../components/action/BaseActionsGroup"
import { filterAvailableActions } from "../../../components/action/utils"
import Title from "../../../components/Display/Title"
import { useTranslation } from "react-i18next"
import { WarningOutlined } from "@mui/icons-material"

/**
 * Filter products by name and returns the ids of the products that match the filter
 * 
 * @param {function} onFilter 
 * @returns 
 */
const ProductsFilter = ({ onFilter, hideCustomConditions }) => {
    const { t } = useTranslation("vbms")
    const [productFilter, setProductFilter] = useState("")
    const [customFilter, setCustomFilter] = useState(false)

    return (
        <Stack direction="row" spacing={2} >
            <TextField
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                label={t("catalog.rates_table.filter.products")}
                onChange={(e) => {
                    setProductFilter(e.target.value)
                    onFilter({
                        product: e.target.value,
                        custom: customFilter
                    })
                }}
            />
            {!hideCustomConditions && (
                <FormControlLabel
                    control={<Checkbox />}
                    label={t("catalog.rates_table.filter.custom")}
                    onChange={(e) => {
                        setCustomFilter(e.target.checked)
                        onFilter({
                            product: productFilter,
                            custom: e.target.checked
                        })
                    }}
                />
            )}
        </Stack>
    )
}

const ProductsTable = ({ products, hideCustomConditions, actions }) => {
    const { i18n } = useTranslation("vbms")
    const [filteredProducts, setFilteredProducts] = useState(products)

    const onFilterHandler = (filter) => {
        if (filter && (filter.product && filter.product.length > 0 || filter.custom)) {
            console.log(filter)
            const productFilter = filter.product && filter.product.length > 0 ? filter.product.toLowerCase() : null
            const filteredProducts = products.filter((product) => {
                if (filter.custom && !product.custom_configuration) {
                    return false
                }

                if (productFilter) {
                    return (
                        product.name.toLowerCase().includes(productFilter) ||
                        product.experience.name.toLowerCase().includes(productFilter)
                    )
                }

                return true
            })

            setFilteredProducts(filteredProducts)
        } else {
            setFilteredProducts(products)
        }
    }

    // sort products by name
    const sortedProducts = filteredProducts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), i18n.language, { ignorePunctuation: true }))

    // group products by experience name
    const groupedProducts = sortedProducts.reduce((acc, product) => {
        const experienceName = product.experience.name
        if (!acc[experienceName]) {
            acc[experienceName] = []
        }
        acc[experienceName].push(product)
        return acc
    }, {})

    return (
        <Stack spacing={2} >
            <ProductsFilter onFilter={onFilterHandler} hideCustomConditions={hideCustomConditions} />
            {groupedProducts && Object.keys(groupedProducts).map((experienceName) => (
                <DataContainer key={experienceName} title={experienceName} titleLevel="h4">
                    {groupedProducts[experienceName].map((product) => (
                        <Box
                            display="flex"
                            mb={4}
                        >
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="center"
                                flexDirection="column"
                                flexGrow={1}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Stack direction="row" justifyContent="space-between" >
                                        <Box
                                            display="flex"
                                            sx={{ "& > div": { fontWeight: 500 } }}
                                        >
                                            {product.custom_configuration && (<WarningOutlined color="warning" sx={{ marginRight: 1 }} />)}
                                            <Title level={"h5"}>{product.name}</Title>
                                        </Box>
                                        <Box mt={-0.5}>
                                            <BaseActionsGroup
                                                selection={product}
                                                variant="outlined"
                                                actions={filterAvailableActions(actions, product)}
                                                actionsNumberDesktop={2}
                                            />
                                        </Box>
                                    </Stack>
                                    <ProductRatesTable product={product} />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </DataContainer>
            ))}
        </Stack>
    )
}

export default ProductsTable