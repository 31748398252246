import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../../context/VolcanoAuthContext"
import { getProductsFilter } from "../../../../../lib/collection-filters"
import EntitySelectorAction from "../../../../Catalog/components/form/EntitySelectionAction"

const ProductSelectorAction = ({ selection, onAction, onClose }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const productsFilter = getProductsFilter(apiClient, t, user, true, {"not_crm_intermediary_id": selection.id})

    return (
        <EntitySelectorAction
            table={productsFilter.table}
            multiple={true}
            valueFormatter={productsFilter.valueFormatter}
            onAction={onAction}
            onClose={onClose}
        />
    )
}


export default ProductSelectorAction